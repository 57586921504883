@import '../../shared.scss';

h2.header {
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 30px;

  @media screen and (max-width: 570px) {
    padding-bottom: 10px;
    border-bottom: 3px solid $blue;
  }

  @media screen and (max-width: 340px) {
    font-size: 24px;
  }

  &::after {
    content: '';
    flex: 1;
    border-bottom: 3px solid $blue;

    @media screen and (max-width: 570px) {
      border: none;
      flex: unset;
      content: unset;
    }
  }
  &:not(:empty)::after {
    margin-left: 0.25em;
  }
}
