@import '../../shared.scss';

div.profile {
  padding: 20px;
  .image {
    figure {
      text-align: center;
      img {
        width: 100%;
        max-width: 250px;
        height: auto;
        border-radius: 200px;
      }
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > *:not(:last-child) {
      margin-bottom: 14px;
    }
    header {
      text-transform: uppercase;
      h1 {
        font-weight: bold;
        font-size: 50px;
        margin: 0;

        @media screen and (max-width: 340px) {
          font-size: 30px;
        }
      }
    }
    .title {
      color: $blue;
      font-weight: 700;
      font-size: 24px;

      @media screen and (max-width: 340px) {
        font-size: 24px;
      }
    }
    .about {
      font-size: 20px;
      margin-bottom: 22px;

      @media screen and (max-width: 340px) {
        font-size: 20px;
      }
    }
    ul.details {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media screen and (max-width: 992px) {
        justify-content: space-around;
      }

      li.icon {
        display: flex;

        @media screen and (max-width: 992px) {
          padding: 0 20px;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 670px) {
          flex-direction: column;
          text-align: center;
          padding: 0 20px;
        }

        &::before {
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          font-size: 26px;
          color: $blue;
          display: inline-block;
          width: 44px;

          @media screen and (max-width: 670px) {
            text-align: center;
            width: 100%;
          }
        }

        &.phone::before {
          content: $icon-phone;
        }
        &.email::before {
          content: $icon-email;
        }
        &.location::before {
          content: $icon-location;
        }
        &.degree::before {
          content: $icon-degree;
          vertical-align: top;
        }
        div {
          display: inline-block;
          p {
            font-weight: 400;
            color: $light-gray;
          }
        }
      }
    }
  }
}
