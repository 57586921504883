@import '../../shared.scss';

body {
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 10px;
    color: $blue;

    span {
      font-weight: bold;
      color: $base-color;
    }
  }
}

.homepage {
  color: $base-color;
  font-family: $primary-font;
  display: grid;
  grid-row-gap: 20px;
  animation: fade 0.9s ease-in;

  @keyframes fade {
    0% {
      opacity: 0;
    }
  }

  .row {
    justify-content: center;

    .column {
      display: flex;
      flex-direction: column;

      & > section {
        margin-bottom: 30px;
      }
    }
  }
}
