@import '../../shared.scss';

.certificates {
  ul.items {
    margin: 0;
    li {
      h3 {
        font-size: 20px;
      }
      h4 {
        color: $base-color;
      }
      h4,
      h5 {
        font-size: 18px;
      }
      a {
        &:link {
          text-decoration: inherit;
          color: $base-color;
        }
        &:hover {
          text-decoration: none;

          .heading {
            h3 {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
