.achievements {
  ul.items {
    li {
      h3 {
        font-size: 20px;
      }
      h4,
      h5 {
        font-size: 18px;
      }
    }
  }
}
