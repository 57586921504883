@import url('https://fonts.googleapis.com/css?family=Ubuntu');

$base-color: #414042;
$blue: #166598;
$light-gray: #939598;
$primary-font: 'Ubuntu', sans-serif;

// fontawesome
$icon-phone: '\f3cd';
$icon-email: '\f0e0';
$icon-location: '\f5a0';
$icon-degree: '\f19d';
