@import '../../shared.scss';

.list-item {
  .row {
    flex-direction: column;
  }
  .heading {
    display: grid;
    grid-template-columns: 1fr 6fr;
    grid-column-gap: 20px;

    @media screen and (max-width: 450px) {
      grid-template-columns: 1fr;
      grid-column-gap: 0;
      grid-row-gap: 20px;
    }

    .logo {
      margin: 0;

      img {
        width: 78px;
      }
    }
  }
  .body {
    @media screen and (max-width: 575px) {
      margin: 0;
      padding: 0;
    }
  }
  h3,
  h4,
  h5 {
    margin-bottom: 2px;
  }
  h3 {
    text-transform: uppercase;
    color: $blue;
    font-weight: 700;
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 20px;
    color: $light-gray;
  }
  ul.details {
    margin-top: 20px;
    padding: 0;
    list-style-position: inside;
    text-indent: -0.9em;
    padding-left: 0.9em;
    list-style-type: square;
  }
  ul.details li::marker {
    color: $blue;
  }
}
