@import '../../shared.scss';

.references {
  .items {
    grid-template-columns: 1fr 1fr;

    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;
    }
    li {
      h3,
      h4,
      h5 {
        margin-bottom: 2px;
      }
      h3 {
        text-transform: uppercase;
        color: $blue;
        font-weight: 700;
        font-size: 20px;
      }
      h4 {
        font-size: 18px;
      }
      h5 {
        font-size: 18px;
        color: $light-gray;
      }
    }
    p {
      margin: 0 0 2px 0;
      padding: 0;
    }
  }
}
