@import '../../shared.scss';

.icon-item-list {
  margin: 0;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 30px;

  @media screen and (max-width: 340px) {
    display: flex;
    flex-direction: column;
  }

  li {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 1fr 5fr;
    @media screen and (min-width: 340px) {
      order: unset !important; // intentional. this overrides the inline `order`
    }
  }

  li.icon {
    &::before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      font-size: 20px;
      color: $blue;
      display: inline-block;
      content: attr(data-icon);
    }
    &.brand::before {
      font-family: 'Font Awesome 5 Brands';
    }
  }

  li.logo {
    figure {
      margin: 0;

      img {
        height: 24px;
        width: 24px;
        object-fit: scale-down;
      }
    }
  }
}
